import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import EnterpriseEligibleButton from 'enterprise/components/EnterpriseEligibleButton'
import styles from './PlansTable.module.scss'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark---circle.svg'
import { ReactComponent as XCircleSvg } from './images/x---circle.svg'

const PLANS_FEATURES = [
  'Price',
  'Personalized exercise programs',
  'Functional fitness assessments',
  'Access to the Bold community',
  'On-demand classes',
  'Live classes & workshops with experts',
  'Be Bold Healthy Aging Summit',
  'Live support from Bold trainers',
]

const PLANS = {
  basic: {
    title: 'Bold Basic',
    description: 'Limited selection of Bold on-demand & live classes.',
    features: ['Free', true, true, true, 'Limited', false, false, false],
  },
  premium: {
    title: 'Bold Premium',
    description:
      'All-access benefits to on-demand and live classes, personalized support, exclusive programs & events.',
    features: ['Starting at $21/month', true, true, true, true, true, true, true],
  },
  enterprise: {
    title: 'Bold Health',
    description:
      'Certain health insurance plans include Bold Premium membership as a benefit. Check your eligibility below.',
    features: ['Free if eligible', true, true, true, true, true, true, true],
    cta: <EnterpriseEligibleButton color="white" full />,
  },
}

type Props = Component

export default function PlansTable({ className }: Props) {
  const featureIncludedCell = (
    <Text element="td">
      <CheckmarkCircleSvg aria-label="Included" className={styles['table--icon']} />
    </Text>
  )

  const featureNotIncludedCell = (
    <Text element="td">
      <XCircleSvg aria-label="Not included" className={styles['table--icon']} />
    </Text>
  )

  return (
    <div className={classNames('PlansTable', className)}>
      <Text
        align="center"
        element="table"
        className={classNames(styles.table, styles['table---large'])}>
        <thead>
          <tr>
            <Text element="th" />
            <Text element="th">{PLANS.basic.title}</Text>
            <Text element="th">{PLANS.premium.title}</Text>
            <Text element="th">{PLANS.enterprise.title}</Text>
          </tr>
          <tr>
            <Text element="th" />
            <Text element="th">{PLANS.basic.description}</Text>
            <Text element="th">{PLANS.premium.description}</Text>
            <Text element="th">{PLANS.enterprise.description}</Text>
          </tr>
          <tr>
            <Text colSpan={99} element="th" color="purple" weight="medium">
              Membership includes
            </Text>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Text element="td">{PLANS_FEATURES[0]}</Text>
            <Text element="td">{PLANS.basic.features[0]}</Text>
            <Text element="td" wrap={false}>
              {PLANS.premium.features[0]}
            </Text>
            <Text element="td">{PLANS.enterprise.features[0]}</Text>
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[1]}</Text>
            {featureNotIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[2]}</Text>
            {featureIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[3]}</Text>
            {featureIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[4]}</Text>
            <Text element="td">Limited</Text>
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[5]}</Text>
            {featureNotIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[6]}</Text>
            {featureNotIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
          <tr>
            <Text element="td">{PLANS_FEATURES[7]}</Text>
            {featureNotIncludedCell}
            {featureIncludedCell}
            {featureIncludedCell}
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <Text colSpan={3} element="td" />
            <Text element="td">
              <EnterpriseEligibleButton color="white" wrap={false}>
                <Text wrap={false}>Check eligibility</Text>
              </EnterpriseEligibleButton>
            </Text>
          </tr>
        </tfoot>
      </Text>
      <div className={styles['table-wrap']}>
        {Object.keys(PLANS).map((itemKey, index) => {
          const item = PLANS[itemKey]
          const { cta, description, features, title } = item

          return (
            <Row flush={index === _.size(PLANS) - 1} key={itemKey} size="large">
              <Text element="table" className={classNames(styles.table, styles['table---small'])}>
                <thead>
                  <tr>
                    <Text colSpan={99} element="th">
                      {title}
                    </Text>
                  </tr>
                  <tr>
                    <Text colSpan={99} element="th">
                      {description}
                    </Text>
                  </tr>
                  <tr>
                    <Text colSpan={99} element="th" color="purple" weight="medium">
                      Membership includes
                    </Text>
                  </tr>
                </thead>
                <tbody>
                  {features.map((feature, featureIndex: number) => (
                    <tr key={featureIndex}>
                      <Text element="td">{PLANS_FEATURES[featureIndex]}</Text>
                      {_.isBoolean(feature) ? (
                        feature ? (
                          featureIncludedCell
                        ) : (
                          featureNotIncludedCell
                        )
                      ) : (
                        <Text element="td">{feature}</Text>
                      )}
                    </tr>
                  ))}
                </tbody>
                {cta && (
                  <tfoot>
                    <tr>
                      <Text colSpan={99} element="td">
                        <Container flush size="xsmall">
                          {cta}
                        </Container>
                      </Text>
                    </tr>
                  </tfoot>
                )}
              </Text>
            </Row>
          )
        })}
      </div>
    </div>
  )
}
