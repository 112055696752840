import React from 'react'
import AuthButton from 'components/AuthButton'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import PlansTable from 'components/PlansTable'
import Row from 'components/Row'
import Section from 'components/Section'
import Title3 from 'components/Title3'
import useEventPageVisited from 'hooks/use-event-page-visited'
import subscriptions from 'libs/subscriptions'

export default function Plans() {
  useEventPageVisited('Plans')

  return (
    <Layout className="Plans">
      <Head title="Memberships" />
      <Section size="large">
        <Container size="xxlarge">
          <Row size="xxlarge">
            <Container flush>
              <Title3 subtext="Learn more about Bold’s variety of membership types. Sign up today and select the option that fits your needs and your budget best.">
                A membership for everybody
              </Title3>
            </Container>
            <PlansTable />
          </Row>
          <Title3
            subtext={`Start your risk free ${subscriptions.TRIAL_DAYS}-day trial today!`}
            actions={<AuthButton color="purple" size="xlarge" />}
            flush>
            Join Bold today
          </Title3>
        </Container>
      </Section>
    </Layout>
  )
}
